import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import logo from "../assets/logo-wstroke.png";
import rowvideo from "../assets/videos/row-video.mp4";
// import Header from '../Header/Header';
import videoCover from "../assets/video-covers/comp/3sq.png";
import OrangeClose from "../assets/icons/comp/close-orange.png";
import playBTN from "../assets/icons/comp/play-6.png";
import awsLogo from "../assets/logos/comp/aws.png";
import beamLogo from "../assets/logos/comp/beam.png";
import bgaLogo from "../assets/logos/comp/bga.webp";
import immutableLogo from "../assets/logos/comp/immutable.png";
import polygonLogo from "../assets/logos/comp/polygon.png";
import startgateLogo from "../assets/logos/comp/startgate.png";
import sphereLogo from "../assets/logos/comp/sphere.webp";
import meritCircleLogo from "../assets/logos/comp/meritcircle.png";
import racoonImage from "../assets/patchImgs/patch-racoon.png";
import unicornImage from "../assets/patchImgs/patch-unicorn.png";
import bearImage from "../assets/patchImgs/patch-bear.png";
import catImage from "../assets/patchImgs/patch-cat.png";
import duckImage from "../assets/patchImgs/patch-duck.png";
import dogImage from "../assets/patchImgs/patch-dog.png";
import piggyIMG from  '../assets/piggyIMG.png'
import videoCover1 from "../assets/video-covers/comp/1.png";
import videoCover2 from "../assets/video-covers/comp/2.png";
import videoCover3 from "../assets/video-covers/comp/3.png";
import videoCover4 from "../assets/video-covers/comp/4.png";
import catImage1 from "../assets/s5-cat.png";
import immutable from "../assets/logos/immutable.png";
import blogImage from "../assets/blog-1.webp";
import chikyImage from "../assets/s6-chiky.png";
import logoImage from "../assets/logo-wstroke.png";
import arenaGameEconomyVideo from "../assets/videos/arena-game-economy.mp4";
import ArenaVideo from "../assets/videos/arena.MP4";
import nftVideo from "../assets/videos/nft.mp4";
import toyFactoryVideo from "../assets/videos/toy-factory.mp4";
import Header from "../componets/Header/Header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bunnyIMG from "../assets/patchImgs/bunny_1.png";
import uniconIMG from "../assets/Tier5.png";
import chickyIMG from "../assets/patchImgs/chicky_1.png";
import piggyIMG1 from "../assets/patchImgs/piggy_1.png";
import racoonIMG from "../assets/patchImgs/racoon_1.png";
import toybearIMG from "../assets/patchImgs/toybear_1.png";
import MoreMarquee from '../assets/Home-Main/MoreMarquee.png'
import BottomCloud from '../assets/Home-Main/bottomCloud1.svg'
import TwitterIcon from '../assets/twitter_icon.svg'
import xIMG from '../assets/Home-Main/xIMG.png'
import RecornIMG from '../assets/RecornIMG.svg'
// import '../../js/main';
// import '../../js/bootstrap';
// import '../../js/360';

function HomePage() {
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);
  const section6Ref = useRef(null);
  const section7Ref = useRef(null);
  const [activeSection, setActiveSection] = useState("section1");
  const handleSectionChange = (sectionId) => {
    setActiveSection(sectionId);
  };
  // useEffect(() => {
  //   const handleScroll = (event) => {
  //     event.preventDefault();

  //     const sections = [
  //       { id: "section1", ref: section1Ref.current },
  //       { id: "section2", ref: section2Ref.current },
  //       { id: "section3", ref: section3Ref.current },
  //       { id: "section4", ref: section4Ref.current },
  //       { id: "section5", ref: section5Ref.current },
  //       { id: "section6", ref: section6Ref.current },
  //       { id: "section7", ref: section7Ref.current },
  //     ];

  //     const scrollDirection = event.deltaY > 0 ? "down" : "up";
  //     const currentScroll = window.scrollY;
  //     let nextSection = null;

  //     for (let i = 0; i < sections.length; i++) {
  //       const section = sections[i];
  //       const sectionTop = section.ref ? section.ref.offsetTop : 0;
  //       const sectionHeight = section.ref ? section.ref.offsetHeight : 0;
  //       if (scrollDirection === "down") {
  //         if (
  //           i === sections.length - 1 &&
  //           currentScroll >= sectionTop - sectionHeight / 2
  //         ) {
  //           nextSection = sections[0]; // Wrap to first section
  //           break;
  //         } else if (currentScroll < sectionTop - sectionHeight / 2) {
  //           nextSection = section;
  //           break;
  //         }
  //       } else if (scrollDirection === "up") {
  //         if (i === 0 && currentScroll <= sectionTop - sectionHeight / 2) {
  //           nextSection = sections[sections.length - 1];
  //           break;
  //         } else if (currentScroll > sectionTop - sectionHeight / 2) {
           
  //           nextSection = sections[i - 1] || sections[0];
  //           break;
  //         }
  //       }
  //     }

  //     if (nextSection) {
  //       setActiveSection(nextSection.id); // Update activeSection state
  //       // onSectionChange(nextSection.id); // Call the onSectionChange function from the Header component

  //       // window.scrollTo({
  //       //   top: nextSection.ref.offsetTop,
  //       //   behavior: "smooth",
  //       // });
  //     }
  //   };

  //   window.addEventListener("wheel", handleScroll, { passive: false });

  //   return () => {
  //     window.removeEventListener("wheel", handleScroll);
  //   };
  // }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const videoContainerRef = useRef(null);
  const videoRef = useRef(null);
  const [selectedPatchIMG, setSelectedPatchIMG] = useState(null);

  const handlePatchIMGClick = (PatchIMGName) => {
    setSelectedPatchIMG(PatchIMGName);
  };

  const renderSelectedPatchIMG = () => {
    switch (selectedPatchIMG) {
      case "piggy":
        return (
          <img
            src={piggyIMG1}
            alt="piggy"
            className="h-[600px] animate-scaleupanddown  w-[750px] absolute right-2 z-20 pb-80 mt-[-70px]  lg:h-[900px] lg:mt-[150px] lg:mr-[-180px]  xl:h-[900px] xl:mt-[80px] xl:mr-[-50px] 1xl:h-[800px] 1xl:mt-[180px] 1xl:mr-[20px] 2xl:h-[1000px] 2xl:mt-[100px] 3xl:h-[1000px] 3xl:w-[1100px] 3xl:mt-[120px] 3xl:mr-[-5px]    5xl:h-[1400px] 5xl:w-[1200px] 5xl:mt-[200px] 5xl:mr-[150px] 6xl:h-[1700px] 6xl:mt-[100px] 6xl:w-[1600px] "
          />
        );
      case "unicorn":
        return (
          <img
            src={uniconIMG}
            alt="unicorn"
            className="h-[900px] animate-scaleupanddown w-[750px] absolute right-2 z-20 pb-80 mt-[-120px]   lg:h-[700px] lg:w-[300px] lg:mt-[300px] lg:mr-[100px]  xl:h-[900px] xl:mt-[120px] xl:mr-[-50px]  1xl:w-[300px] 1xl:h-[700px] 1xl:mt-[260px] 1xl:mr-[240px] 2xl:h-[1000px] 2xl:mt-[100px] 3xl:h-[900px] 3xl:w-[400px]  3xl:mt-[150px] 3xl:mr-[300px]   5xl:h-[1200px] 5xl:w-[600px] 5xl:mt-[230px] 5xl:mr-[450px]  6xl:h-[1200px] 6xl:w-[600px] 6xl:mr-[450px]"
          />
        );
      case "bear":
        return (
          <img
            src={toybearIMG}
            alt="bear"
            className="h-[900px] animate-scaleupanddown w-[750px] absolute right-2 z-20 pb-80 mt-[-120px]  lg:h-[900px] lg:mt-[150px] lg:mr-[-180px] xl:h-[900px] xl:mt-[130px] xl:mr-[-50px] 1xl:h-[800px] 1xl:mt-[200px] 1xl:mr-[-20px] 2xl:h-[1000px] 2xl:mt-[150px]  3xl:h-[1000px] 3xl:w-[1100px] 3xl:mt-[120px] 3xl:mr-[-5px]   5xl:h-[1400px] 5xl:w-[1200px] 5xl:mt-[200px] 5xl:mr-[200px]  6xl:h-[1700px] 6xl:w-[1600px] 6xl:mt-[50px]"
          />
        );
      case "racoon":
        return (
          <img
          src={racoonIMG}
          alt="racoon"
            className="h-[900px] animate-scaleupanddown w-[750px] absolute right-2 z-20 pb-80 mt-[-120px]  lg:h-[900px] lg:mt-[150px] lg:mr-[-180px]  xl:h-[900px] xl:mt-[80px] xl:mr-[-50px] 1xl:h-[800px] 1xl:mt-[200px] 1xl:mr-[-20px] 2xl:h-[1000px] 2xl:mt-[120px] 3xl:h-[1000px] 3xl:w-[1000px] 3xl:mt-[120px] 3xl:mr-[30px] 5xl:h-[1400px] 5xl:w-[1200px] 5xl:mt-[250px] 5xl:mr-[200px] 6xl:h-[1700px] 6xl:mt-[180px] 6xl:w-[1600px]"
          />
        );
      case "chicky":
        return (
          <img
            src={chickyIMG}
            alt="chicky"
            className="h-[900px] animate-scaleupanddown w-[750px] absolute right-2 z-20 pb-80 mt-[-120px]  lg:h-[900px] lg:mt-[80px] lg:mr-[-180px] xl:h-[900px] xl:mt-[80px] xl:mr-[-50px] 1xl:h-[800px] 1xl:mt-[200px] 1xl:mr-[-20px] 2xl:h-[1000px] 2xl:mt-[150px]  3xl:h-[1000px]  3xl:w-[1100px] 3xl:mt-[120px] 3xl:mr-[40px] 5xl:h-[1400px] 5xl:w-[1200px] 5xl:mt-[200px]  5xl:mr-[200px] 6xl:h-[1700px] 6xl:w-[1600px] 6xl:mt-[50px]"
          />
        );
      case "bunny":
        return (
          <img
            src={bunnyIMG}
            alt="bunny"
            className="h-[900px] animate-scaleupanddown  w-[750px] absolute right-2 z-20 pb-80 mt-[-120px]  lg:h-[900px] lg:mt-[80px] lg:mr-[-180px] xl:h-[900px] xl:mt-[80px] xl:mr-[-50px] 1xl:h-[1000px] 1xl:mt-[150px] 1xl:mr-[-20px] 2xl:h-[1000px] 2xl:mt-[150px]  3xl:h-[1250px]  3xl:w-[1100px] 3xl:mt-[150px] 3xl:mr-[-5px] 4xl:mt-[500px] 5xl:h-[1400px] 5xl:w-[1200px] 5xl:mt-[200px] 5xl:mr-[200px]  6xl:h-[1700px] 6xl:w-[1600px]"
          />
        );
      default:
        return null;
    }
  };

  const handlePlayButtonClick = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      // Request full screen
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      } else if (videoElement.mozRequestFullScreen) {
        /* Firefox /
        videoElement.mozRequestFullScreen();
      } else if (videoElement.webkitRequestFullscreen) { / Chrome, Safari and Opera /
        videoElement.webkitRequestFullscreen();
      } else if (videoElement.msRequestFullscreen) { / IE/Edge */
        videoElement.msRequestFullscreen();
      }
      // Play the video
      videoElement.play();
    }
  };

  // const [imagePositions, setImagePositions] = useState([
  //   'translate-x-0 translate-y-0', // top left
  //   'translate-x-0 translate-y-0', // top right
  //   'translate-x-0 translate-y-0', // bottom left
  //   'translate-x-0 translate-y-0', // bottom right
  // ]);

  // const openVideo = (videoSrc) => {
  //   // setImagePositions([
  //   //   'translate-x-full translate-y-full opacity-0', // top left
  //   //   'translate-x-full -translate-y-full opacity-0', // top right
  //   //   '-translate-x-full translate-y-full opacity-0', // bottom left
  //   //   '-translate-x-full -translate-y-full opacity-0', // bottom right
  //   // ]);

  //   // // Show the video container with a fade-in effect
  //   // videoContainerRef.current.style.opacity = 0;
  //   // videoContainerRef.current.style.display = 'flex';
  //   // setTimeout(() => {
  //   //     videoContainerRef.current.style.opacity = 1;
  //   // }, 100); // Adjust the delay as needed
  //   //const videoSrc = event.target.dataset.videoSrc || rowVideo;
  //   const videoContainer = videoContainerRef.current;

  //   // Create a new video element
  //   const videoElement = document.createElement("video");
  //   videoElement.src = videoSrc;
  //   videoElement.controls = true;
  //   videoElement.className = 'max-w-full max-h-full';
  //   videoElement.width = 800;
  //   videoElement.height = 600;

  //   // Create a close button
  //   const closeButton = document.createElement("button");
  //   closeButton.className = "absolute top-2 right-2 z-10";
  //   const closeIconElement = document.createElement("img");
  //   closeIconElement.src = OrangeClose;
  //   closeIconElement.alt = "Close";
  //   closeIconElement.className = "w-6 h-6";
  //   closeButton.appendChild(closeIconElement);
  //   closeButton.addEventListener("click", () => {
  //     videoContainer.style.display = "none";
  //   });

  //   // Clear the video container
  //   videoContainer.innerHTML = "";

  //   // Append the video element to the container
  //   videoContainer.appendChild(videoElement);
  //   videoContainer.appendChild(closeButton);

  //   // Show the video container
  //   videoContainer.style.display = "flex";
  // };

  const openVideo = (videoSrc) => {
    const videoContainer = videoContainerRef.current;
    if (!videoContainer) {
      console.error("Video container not found");
      return;
    }

    // Create a new video element
    const videoElement = document.createElement("video");
    videoElement.src = videoSrc;
    videoElement.controls = true;
    videoElement.className = "max-w-full max-h-full";
    videoElement.width = 800;
    videoElement.height = 600;

    // Create a close button
    const closeButton = document.createElement("button");
    closeButton.className = "absolute top-2 right-2 z-10";
    const closeIconElement = document.createElement("img");
    closeIconElement.src = OrangeClose;
    closeIconElement.alt = "Close";
    closeIconElement.className = "w-6 h-6";
    closeButton.appendChild(closeIconElement);
    closeButton.addEventListener("click", () => {
      videoContainer.style.display = "none";
    });

    // Clear the video container
    videoContainer.innerHTML = "";

    // Append the video element to the container
    videoContainer.appendChild(videoElement);
    videoContainer.appendChild(closeButton);

    // Show the video container
    videoContainer.style.display = "flex";
  };

  const logos = [
    { src: awsLogo, alt: "AWS Logo" },
    { src: beamLogo, alt: "Beam Logo" },
    { src: bgaLogo, alt: "BGA Logo" },
    { src: immutableLogo, alt: "Immutable Logo" },
    { src: polygonLogo, alt: "Polygon Logo" },
    { src: startgateLogo, alt: "Startgate Logo" },
    { src: sphereLogo, alt: "Sphere Logo" },
    { src: meritCircleLogo, alt: "Merit Circle Logo" },
    // Repeat the logos to ensure continuous marquee
    { src: awsLogo, alt: "AWS Logo" },
    { src: beamLogo, alt: "Beam Logo" },
    { src: bgaLogo, alt: "BGA Logo" },
    { src: immutableLogo, alt: "Immutable Logo" },
    { src: polygonLogo, alt: "Polygon Logo" },
    { src: startgateLogo, alt: "Startgate Logo" },
    { src: sphereLogo, alt: "Sphere Logo" },
    { src: meritCircleLogo, alt: "Merit Circle Logo" },
  ];

  return (
    <div>
       <Header
        activeSection={activeSection}
        onSectionChange={handleSectionChange}
      />

      <div>
        <div id="video-player" className="video-section">
          <video
            id="game-video"
            poster={videoCover}
            preload="auto"
            muted
            autoPlay
            ref={videoRef}
            height="400"
            width="600">
            <source src={ArenaVideo} type="video/MP4" />
            Your browser does not support the video tag.
          </video>
          <img
            id="play-button"
            className=""
            src={playBTN}
            alt="Play Button"
            onClick={handlePlayButtonClick}
          />
        </div>
      </div>
      <>
        {/* <div
              id="section1"
              className="row scroll-section active fullscreen-section">
              <div className="col-12 h-full">
                <div className="w-screen flex justify-center items-center mt-10 herologo">
                  <img
                    src={logo}
                    alt="logo"
                    loading="lazy"
                    className="h-[478px] 2xs:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[450px] 2xl:h-[450px] 3xl:h-[580px] 4xl:h-[640px] mt-32 xl:mt-36 1xl:mt-56 2xl:mt-40 3xl:mt-72 4xl:mt-40 5xl:mt-48 5xl:h-[780px] 6xl:w-[950px]"
                  />
                </div>
                <h3 className="bouncy-thin-font text-[32px] text-white mt-4 mb-16 xl:text-[38px] 2xl:text-[40px] 3xl:text-[46px] 5xl:text-[64px] 6xl:text-[64px] text-center">
                  CUTE WINS, PSYCHO FUN
                </h3>
              </div>
            </div> */}
        <div
          id="section1"
          ref={section1Ref}
          className="row scroll-section fullscreen-section">
          <div className="col-12 h-full">
            <div className="w-screen flex justify-center items-center mt-10 herologo">
              <img
                src={logo}
                alt="logo"
                loading="lazy"
                className="3xs:h-[165px] 2xs:h-[240px] xsm:h-[280px] sm:h-[300px] md:h-[320px] md-1:h-[350px] lg:h-[380px] xl:h-[380px] 3xl:h-[420px] 4xl:h-[550px] 5xl:h-[600px] 6xl:w-[750px] 7xl:h-[800px] 8xl:w-[850px] mt-32 xl:mt-34 1xl:mt-40 2xl:mt-44 3xl:mt-52 4xl:mt-40 5xl:mt-48"
              />
            </div>
            {/* <h3 className="bouncy-thin-font 3xs:text-[22px] xsm:text-[26px] sm:text-[28px] text-white mt-4 mb-16 xl:text-[30px] 2xl:text-[32px] 3xl:text-[36px] 5xl:text-[64px] 6xl:text-[64px] text-center">
              CUTE WINS, PSYCHO FUN
            </h3> */}
          </div>
        </div>

        <div
          id="section2"
          ref={section2Ref}
          className="row scroll-section fullscreen-section w-screen h-screen">
          <img
            className="section2-cute-btn hidden w-[32vw] left-[4vw] mt-[16vh] absolute"
            src=""
            alt="Cute Button"
          />
          <img
            className="section2-psycho-btn hidden w-[32vw] right-[4vw] mt-[16vh] absolute"
            src=""
            alt="Psycho Button"
          />
          <div className="flex justify-center items-center relative">
            <video
              className="t-0 h-[88vh] lg:w-screen object-cover object-center"
              src={rowvideo}
              autoPlay
              loop
              muted
              playsInline></video>
          </div>

          <div className="bg-gradient-to-r w-screen from-transparent via-black to-transparent h-[12vh] overflow-hidden">
            <div className="logo-container flex marquee-scroll ">
              {logos.map((logo, index) => (
                <img
                  key={index}
                  src={logo.src}
                  alt={logo.alt}
                  className="mr-10 3xs:h-[40px] xl:h-[60px] 4xl:h-[100px] 5xl:h-[110px] 8xl:h-[120px] mt-4 xl:mt-6 3xl:mt-10 5xl:mt-14 6xl:mt-16 7xl:mt-18 8xl:mt-20"
                />
              ))}
            </div>
          </div>
        </div>

        <div
          id="section3"
          ref={section3Ref}
          className="row scroll-section fullscreen-section overflow-hidden w-screen">
          <div
            id="section3-desktop"
            className="row md:hidden lg:flex"
            style={{ margin: 0, padding: 0 }}>
            <div className="triangle-column w-screen flex-wrap">
              <div className="triangle-container">
                <div className="selected-ponchiq-container">
                  {renderSelectedPatchIMG()}
                </div>
                <div className="angled-text">
                  <p className="w-screen">
                    SELECT YOUR PONCHIQS SELECT YOUR PONCHIQS SELECT YOUR
                    PONCHIQS
                  </p>
                </div>
                <div className="mini-images-column patch-images">
                  <img
                    src={piggyIMG}
                    alt="Ponchiq 1"
                    className="mini-image first "
                    data-ponchiq="racoon"
                    onClick={() => handlePatchIMGClick("piggy")}

                  />
                  
                  <img
                    src={unicornImage}
                    id="mini-image-second"
                    alt="Ponchiq 2"
                    className="mini-image second"
                    data-ponchiq="unicorn"
                    onClick={() => handlePatchIMGClick("unicorn")}
                  />
                  
                  
                  <img
                   src={racoonImage}
                   
                    alt="Ponchiq 3"
                    className="mini-image fifth"
                    data-ponchiq="chicky"
                    onClick={() => handlePatchIMGClick("racoon")}
                  />
                  <img
                  src={bearImage}
                    alt="Ponchiq 4"
                    className="mini-image fourth"
                    data-ponchiq="piggy"
                    onClick={() => handlePatchIMGClick("bear")}
                  />
 <img
                    src={duckImage}
                    alt="Ponchiq 3"
                    className="mini-image third"
                    data-ponchiq="bear"
                 
                    onClick={() => handlePatchIMGClick("chicky")}
                  /> 

                 
                  {/* <img
                    src={dogImage}
                    alt="Ponchiq 6"
                    className="mini-image sixth"
                    data-ponchiq="bunny"
                    onClick={() => handlePatchIMGClick("bunny")}
                  /> */}
                </div>
                <div id="model-container" className="model-container">
                  <div id="model" className="model"></div>
                </div>
              </div>
            </div>
            <div className="s3-slider overflow-hidden">
              <div className="s3-container">
                <p>
                  <span className="font-bouncy font-extrabold"> PIONEER *</span>{" "}
                  <span className="font-bouncyWhite">SHOOT </span>
                  <span className="font-bouncy font-extrabold">* LEARN *</span>
                  <span className="font-bouncyWhite"> PIONEER </span>{" "}
                  <span className="font-bouncy font-extrabold">* SHOOT *</span>
                  <span className="font-bouncyWhite"> LEARN </span>
                  <span className="font-bouncy font-extrabold">
                    {" "}
                    * PIONEER *
                  </span>{" "}
                  <span className="font-bouncyWhite">SHOOT </span>
                  <span className="font-bouncy font-extrabold">* LEARN *</span>
                  <span className="font-bouncyWhite"> PIONEER </span>{" "}
                  <span className="font-bouncy font-extrabold">* SHOOT * </span>
                  <span className="font-bouncyWhite"> LEARN </span>
                  <span className="font-bouncy font-extrabold">
                    * PIONEER *
                  </span>{" "}
                  <span className="font-bouncyWhite">SHOOT </span>
                  <span className="font-bouncy font-extrabold">* LEARN *</span>
                  <span className="font-bouncyWhite"> PIONEER </span>{" "}
                  <span className="font-bouncy font-extrabold">* SHOOT *</span>
                  <span className="font-bouncyWhite"> LEARN </span>
                  <span className="font-bouncy font-extrabold">
                    {" "}
                    * PIONEER*{" "}
                  </span>{" "}
                  <span className="font-bouncyWhite">SHOOT </span>
                  <span className="font-bouncy font-extrabold">* LEARN *</span>
                  <span className="font-bouncyWhite"> PIONEER </span>{" "}
                  <span className="font-bouncy font-extrabold">* SHOOT * </span>
                  <span className="font-bouncyWhite">* LEARN *</span>
                  <span className="font-bouncy font-extrabold">
                    {" "}
                    PIONEER *
                  </span>{" "}
                  <span className="font-bouncyWhite">SHOOT </span>
                  <span className="font-bouncy font-extrabold">* LEARN *</span>
                  <span className="font-bouncyWhite"> PIONEER *</span>{" "}
                  <span className="font-bouncy font-extrabold">* SHOOT * </span>
                  <span className="font-bouncyWhite"> LEARN </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="section5" ref={section5Ref} className="mx-auto relative ">
          <div className="h-[250px] 3xs:h-0">
            <img src={MoreMarquee} alt="" className="w-screen" />
          </div>
          <div className="h-[100vh] 3xs:h-[0vh] lg:h-[100vh] ">
            <div className="flex flex-wrap h-full p-5 3xs:hidden md:hidden md-1:hidden lg:flex lg:flex-wrap animate-scaleupanddown">
              <div className="arena-image1 relative w-1/2 top-1/5 left-[20%] cursor-pointer transition-all duration-1000 ease-in-out 3xs:mt-[100px] 2xs:mt-[0px] xs:mt-0 lg:w-1/2 lg:mt-[200px] xl:mt-[200px] 1xl:mt-[220px] 2xl:mt-[250px] 3xl:mt-[200px] 4xl:mt-[300px] 5xl:mt-[500px] 6xl:mt-[500px] 7xl:mt-[550px] 8xl:mt-[600px] ">
                <img
                  src={videoCover4}
                  className="w-[450px] left-first z-10 lg:w-[300px] 3xl:w-[450px] 4xl:w-[500px] 5xl:w-[550px] 6xl:w-[600px] 7xl:w-[650px] 8xl:w-[650px]"
                  alt="Game Economy Video"
                  data-video-src="assets/videos/arena-game-economy.mp4"
                  onClick={() => openVideo(arenaGameEconomyVideo)}
                />
                <img
                  src={videoCover2}
                  className="w-[450px]  left-second lg:w-[300px] 3xl:w-[450px] 4xl:w-[500px] 5xl:w-[550px] 6xl:w-[600px] 7xl:w-[650px] 8xl:w-[650px] z-20"
                  alt="NFT Video"
                  data-video-src="assets/videos/nft.mp4"
                  onClick={() => openVideo(toyFactoryVideo)}
                />
              </div>
              <div className="arena-image2 relative w-1/2 top-1/5 left-[5%] cursor-pointer transition-all duration-1000 ease-in-out 3xs:mt-[100px] 2xs:mt-[0px] xs:mt-0 lg:w-1/2 lg:mt-[200px] xl:mt-[200px] 1xl:mt-[250px] 2xl:mt-[250px] 3xl:mt-[200px] 4xl:mt-[300px] 5xl:mt-[500px] 6xl:mt-[500px] 7xl:mt-[550px] 8xl:mt-[600px] z-30">
                <img
                  src={videoCover1}
                  className="w-[450px] right-first lg:w-[300px] 3xl:w-[450px] 4xl:w-[500px] 5xl:w-[550px] 6xl:w-[600px] 7xl:w-[650px] 8xl:w-[650px]"
                  alt="Toy Factory Video"
                  data-video-src="assets/videos/toy-factory.mp4"
                  onClick={() => openVideo(nftVideo)}
                />
                <img
                  src={videoCover3}
                  className="w-[450px] right-second lg:w-[300px] 3xl:w-[450px] 4xl:w-[500px] 5xl:w-[550px] 6xl:w-[600px] 7xl:w-[650px] 8xl:w-[650px]"
                  alt="Arena Video"
                  data-video-src="assets/videos/arena.MP4"
                  onClick={() => openVideo(ArenaVideo)}
                />
              </div>
              <div
                ref={videoContainerRef}
                className="arena-video-element fixed place-content-center hidden z-30 arena-video-zoom lg:size-fit lg:top-[200px] lg:left-[270px] lg:w-[500px] xl:size-fit xl:top-[200px] xl:left-[300px] xl:w-[650px] 1xl:size-fit 1xl:top-[250px] 1xl:left-[370px] 1xl:w-[700px] 2xl:size-max 2xl:left-[400px] 2xl:w-[760px] 3xl:size-fit 3xl:top-[320px] 3xl:left-[480px] 3xl:w-[800px] 4xl:size-max 4xl:top-[400px] 4xl:left-[780px] 5xl:size-max 5xl:top-[500px] 5xl:left-[980px] 5xl:justify-center 5xl:items-center 6xl:size-max 6xl:top-[600px] 6xl:left-[1150px]">
                <button
                  className="absolute top-2 right-2 z-10"
                  onClick={() => {
                    videoContainerRef.current.style.display = "none";
                  }}>
                  <img src={OrangeClose} alt="Close" className="w-10 h-10" />
                </button>
              </div>
            </div>

            <div id="section4-video-container" className="hidden">
              <video id="section4-video" controls src=""></video>
              <img
                id="section4-close-button"
                src="assets/icons/comp/close-orange.png"
                alt="Close Video Button"
              />
            </div>

            <div className="absolute bottom-0 z-[-1] w-full">
              <img src={BottomCloud} alt="Bottom Cloud" className="w-screen z-[-40]" />
            </div> 
          </div>
          <div
            id="section4-mobile"
            className="md-1:hidden 3xs:mt-[220px] 3xs:h-[200px] mt-20 md-1:mt-80 md-1:ml-40 lg:hidden">
            <Slider {...settings}>
              <div className="flex justify-center items-center">
                <img
                  name="section4-mobile-image"
                  src={videoCover4}
                  className="w-screen flex justify-center items-center md-1:w-[500px]"
                  data-video-src="assets/videos/arena-game-economy.mp4"
                  alt="Game Economy Video"
                />
              </div>
              <div>
                <img
                  name="section4-mobile-image"
                  src={videoCover3}
                  className="d-block w-full"
                  data-video-src="assets/videos/arena.MP4"
                  alt="Arena Video"
                />
              </div>
              <div>
                <img
                  name="section4-mobile-image"
                  src={videoCover1}
                  className="d-block w-full"
                  data-video-src="assets/videos/toy-factory.mp4"
                  alt="Toy Factory Video"
                />
              </div>
              <div>
                <img
                  name="section4-mobile-image"
                  src={videoCover2}
                  className="d-block w-full"
                  data-video-src="assets/videos/nft.mp4"
                  alt="NFT Video"
                />
              </div>
            </Slider>
          </div>
        </div>

        <div
          id="section6"
          ref={section6Ref}
          className="w-full  h-[100vh]  sm:h-[85vh]  lg:h-[80vh] xl:h-[70vh] 1xl:h-[75vh] 2xl:h-[70vh] 3xl:h-[75vh] 5xl:h-[76vh] 6xl:h-[78vh] 7xl:h-[78vh] ">
          <div>
            <img src={BottomCloud} alt="" className="w-screen rotate-180" />
          </div>
          <div className=" w-full flex justify-center items-center 3xs:mt-[300px] lg:mt-[250px] xl:mt-[330px] 1xl:mt-[200px] 2xl:mt-[200px] 3xl:mt-[200px] 4xl:mt-[250px]  5xl:mt-[300px] 6xl:mt-[350px] 7xl:mt-[400px] 8xl:mt-[500px]">
            <div className="absolute  flex items-center lg:ml-[160px]">
              <img
                src={RecornIMG}
                className=" absolute left-[-180px] top-1/2 transform -translate-y-1/2  object-cover z-10 p-10 3xs:left-[-70px] 3xs:w-[200px] lg:w-[450px] lg:h-[690px] lg:left-[-240px]  "
                alt="catimage"
              />
              <button
                className="connect-butt on-x pl-4 pr-4 shine relative ml-14 rounded-[60px] bg-black bg-opacity-30 text-black text-[1.5rem] font-bold capitalize md:text-[32px] xl:text-[48px] p-4"
              >
                <div className="flex justify-center items-center  font-LuckiestGuy ">
                  <div className="text-shadow-black text-stroke-1-black 2xs:text-white 2xs:mx-4 4xl:mx-8">
                    <span className="text-shadow-black text-stroke-1-black 2xs:text-homeleman p-2">
                      CONNECT
                    </span>
                    WITH
                  </div>
                  <div>
                    <img
                      src={xIMG}
                      alt="TwitterIcon"
                      className=" h-[60px]"
                      loading="lazy"
                    />
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div>
          <img src={BottomCloud} alt="" className="w-screen" />
        </div>
        <div
          id="section7"
          ref={section7Ref}
          className="mx-auto relative "></div>
      </>
    </div>
  );
}

export default HomePage;
