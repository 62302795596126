import React, { useState, Suspense, lazy } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Loader from "./componets/Loader/Loader";
import SkyBackground from "./componets/skybg/skyBackground";
import PonchLogo from "./assets/Home_logo.png";
import CursorImage from "./componets/cursorImgAnimation/CursorImage";
import ProtectedRoute from "./componets/routes/protectedRoute";
import HomePage from "./pages/HomePage";
import ScrollToTop from "./componets/handleScroll/handlescroll";
const Header = lazy(() => import("./componets/header"));
// import Header from "./componets/Header/Header";
const Footer = lazy(() => import("./componets/footer"));
const Home = lazy(() => import("./pages/Home"));
const Points = lazy(() => import("./pages/Dashboard/Points"));
const Emailpopup = lazy(
  () => import("./componets/modal/EmailPopup/Emailpopup")
);
const AuthorizeGrantedPopup = lazy(
  () => import("./componets/modal/AuthorizeGrantedPopup/AuthorizeGrantedPopup")
);
const SigninPopup = lazy(
  () => import("./componets/modal/SigninPopup/SigninPopup")
);
const Privacy = lazy(() => import("./pages/Policy/Privacy"));
const Terms = lazy(() => import("./pages/Policy/Terms"));

function App() {
  const [showHeaderFooter, setShowHeaderFooter] = useState(true);

  return (
    <>
      <Router>
        <SkyBackground />
        {/* <CursorImage />   */}
        <ScrollToTop/>  
        <Suspense
          fallback={
            <div className="min-h-screen flex items-center justify-center  bg-transparent z-50 ">
              <div className="h-20 animate-bounce">
                <img src={PonchLogo} alt="ponch_logo" width={400} height={400}/>
              </div>
            </div>
          }
        >
          {showHeaderFooter && <Header />}
          <Routes>
           <Route path="/" element={<Navigate to="/socialfi" />} />
        <Route path="/socialfi" element={<Home />} />
            {/* <Route path="/socialfi-home" exact element={<Home />} /> */}
            {/* <Route path="/" exact element={<HomePage />}/> */}
            <Route
              path="/signin"
              element={
                <SigninPopup setShowHeaderFooter={setShowHeaderFooter} />
              }
            />
       
            <Route
              path="/points"
              exact
              element={
                <ProtectedRoute element={Points} />
              }
            />
            <Route
              path="/email"
              element={<Emailpopup setShowHeaderFooter={setShowHeaderFooter} />}
            />
            <Route
              path="/auth"
              exact
              element={
                <AuthorizeGrantedPopup
                  setShowHeaderFooter={setShowHeaderFooter}
                />
              }
            />
            <Route path="/Privacy" exact element={<Privacy />} />
            <Route path="/Terms" exact element={<Terms />} />
          </Routes>
          {showHeaderFooter && <Footer />}
        </Suspense>
      </Router>
    </>
  );
}

export default App;

